import React from "react"
import { motion } from "framer-motion"

const HowItWorks = () => {
	return (
		<section className="flex flex-col items-center justify-center w-full min-h-[60vh] bg-transparent px-4 2xl:px-8 py-12 2xl:py-20">
			{/* Animated image container */}
			<motion.div
				className="w-full max-w-7xl 2xl:max-w-8xl relative -mt-16"
				initial={{ opacity: 0, y: 30, scale: 0.95 }}
				animate={{ opacity: 1, y: 0, scale: 1 }}
				transition={{
					duration: 1.2,
					delay: 2.4, // Delay until after hero animations
					ease: "easeOut",
				}}
			>
				<div className="bg-surface2/30 backdrop-blur-xs p-8 rounded-xl">
					<img
						src="https://jigjoy-website.s3.eu-west-1.amazonaws.com/demopic.png"
						alt="Hero visual"
						className="rounded-xl w-full h-auto shadow-lg"
					/>
				</div>
			</motion.div>
		</section>
	)
}

export default HowItWorks
