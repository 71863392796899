import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"

const faqs = [
	{
		question: "What is JigJoy?",
		answer: `JigJoy turns your ideas into real apps using AI. No coding needed.`,
	},
	{
		question: "How does it work?",
		answer: `Tell us what you want to build. Our AI creates your app instantly.`,
	},
	{
		question: "Do I need to code?",
		answer: `Not at all! Just describe what you want in simple words.`,
	},
	{
		question: "Can I make changes?",
		answer: `Yes! Change anything anytime - colors, features, layout. Just ask.`,
	},
	{
		question: "What can I build?",
		answer: `Anything from simple websites to full apps with login and databases.`,
	},
	{
		question: "How fast is it?",
		answer: `Get your app running in minutes, not weeks.`,
	},
	{
		question: "Where will my app live?",
		answer: `Your app gets its own web address instantly. Share it with anyone.`,
	},
]

const FrequentlyAskedQuestions = () => {
	const [openIndex, setOpenIndex] = useState(null)

	const toggleFAQ = (index) => {
		setOpenIndex(openIndex === index ? null : index)
	}

	return (
		<section id="faq" className="max-w-4xl mx-auto px-6 py-20 scroll-mt-20">
			<h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-heading">Frequently Asked Questions</h2>
			<div className="space-y-6">
				{faqs.map((faq, index) => {
					const isOpen = openIndex === index
					return (
						<div
							key={index}
							className="border border-outline rounded-lg p-4 hover:border-accent transition-colors duration-200"
						>
							<button
								onClick={() => toggleFAQ(index)}
								className="flex justify-between items-center w-full text-left focus:outline-hidden group"
							>
								<span className="text-xl font-medium text-heading group-hover:text-accent transition-colors duration-200">
									{faq.question}
								</span>
								<svg
									className={`w-5 h-5 text-accent transition-transform duration-300 ${isOpen ? "transform rotate-180" : ""}`}
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
								>
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
								</svg>
							</button>
							<AnimatePresence>
								{isOpen && (
									<motion.div
										key="content"
										initial={{ height: 0, opacity: 0 }}
										animate={{ height: "auto", opacity: 1 }}
										exit={{ height: 0, opacity: 0 }}
										transition={{ duration: 0.3 }}
										className="overflow-hidden"
									>
										<div className="text-lg mt-4 text-base text-base">{faq.answer}</div>
									</motion.div>
								)}
							</AnimatePresence>
						</div>
					)
				})}
			</div>
		</section>
	)
}

export default FrequentlyAskedQuestions
