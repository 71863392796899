import React from "react"
import { motion } from "framer-motion"

// Example projects built with JigJoy
const projects = [
	{
		title: "Travel Tales",
		description: "Sarah's travel blog with photo galleries and interactive maps - built in 10 minutes",
		badge: "Blog",
		stats: {
			views: "2.4k",
			photos: "156",
			countries: "12",
		},
		icons: {
			main: "✈️",
			stats: ["👀", "📸", "🌎"],
		},
	},
	{
		title: "Family Cookbook",
		description: "Mike's recipe collection app with ingredient scaling and meal planning",
		badge: "Food",
		stats: {
			recipes: "84",
			saved: "1.2k",
			rating: "4.9",
		},
		icons: {
			main: "👨‍🍳",
			stats: ["📖", "💾", "⭐"],
		},
	},
	{
		title: "Local Events Hub",
		description: "Community event platform with RSVPs and automatic reminders by Lisa",
		badge: "Events",
		stats: {
			events: "45",
			members: "320",
			rsvps: "180",
		},
		icons: {
			main: "🎉",
			stats: ["📅", "👥", "✅"],
		},
	},
	{
		title: "Fitness Journey",
		description: "Tom's workout progress tracker with photo timelines and achievement badges",
		badge: "Health",
		stats: {
			workouts: "68",
			badges: "12",
			streak: "24",
		},
		icons: {
			main: "💪",
			stats: ["🏋️‍♂️", "🏅", "🔥"],
		},
	},
]

// Animation variants
const containerVariants = {
	hidden: { opacity: 0 },
	visible: {
		opacity: 1,
		transition: {
			staggerChildren: 0.15,
		},
	},
}

const itemVariants = {
	hidden: { opacity: 0, y: 40 },
	visible: {
		opacity: 1,
		y: 0,
		transition: { duration: 0.5, ease: "easeOut" },
	},
}

const statsVariants = {
	hidden: { scale: 0.8, opacity: 0 },
	visible: (i) => ({
		scale: 1,
		opacity: 1,
		transition: {
			delay: i * 0.1,
			type: "spring",
			stiffness: 400,
			damping: 15,
		},
	}),
}

const iconVariants = {
	hidden: { scale: 0, rotate: -180 },
	visible: {
		scale: 1,
		rotate: 0,
		transition: {
			type: "spring",
			stiffness: 200,
			damping: 15,
		},
	},
}

const ProjectsShowcase = () => {
	return (
		<section id="projects" className="max-w-6xl 2xl:max-w-8xl mx-auto px-4 2xl:px-8 py-20 2xl:py-28 scroll-mt-20">
			<div className="text-center mb-12">
				<h2 className="text-3xl md:text-4xl font-bold text-heading mb-4">Community Creations</h2>
				<p className="text-base text-xl max-w-2xl mx-auto">
					Real apps built by real people using JigJoy. No coding skills required - just ideas brought to life!
				</p>
			</div>

			<motion.div
				className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4"
				variants={containerVariants}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true, margin: "-100px" }}
			>
				{projects.map(({ title, description, badge, stats, icons }, index) => (
					<motion.div
						key={index}
						variants={itemVariants}
						className="bg-surface2/30 backdrop-blur-xs rounded-lg overflow-hidden shadow-lg border border-outline hover:border-accent transition-colors duration-200"
						whileHover={{ y: -5 }}
					>
						<div className="p-6">
							<motion.div className="text-4xl mb-4" variants={iconVariants}>
								{icons.main}
							</motion.div>
							<span className="inline-block px-3 py-1 text-xs font-semibold text-base bg-surface3 rounded-full mb-3">
								{badge}
							</span>
							<h3 className="text-xl font-semibold mb-2 text-heading">{title}</h3>
							<p className="text-base mb-6">{description}</p>

							<div className="grid grid-cols-3 gap-3">
								{Object.entries(stats).map(([key, value], i) => (
									<motion.div key={key} custom={i} variants={statsVariants} className="text-center">
										<div className="text-xl mb-1">{icons.stats[i]}</div>
										<div className="text-lg font-bold text-accent">{value}</div>
										<div className="text-xs text-base capitalize">{key}</div>
									</motion.div>
								))}
							</div>
						</div>
					</motion.div>
				))}
			</motion.div>
		</section>
	)
}

export default ProjectsShowcase
