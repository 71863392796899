import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"

const CustomizationAnimation = () => {
	const [step, setStep] = useState(0)
	const totalSteps = 4
	const [text, setText] = useState("")
	const promptText = "Make it more playful and colorful"
	const [isInView, setIsInView] = useState(false)

	const aiThoughts = [
		"Analyzing design preferences...",
		"Updating color scheme to be more vibrant...",
		"Making buttons more playful...",
		"Adding fun micro-interactions...",
	]

	const colors = {
		initial: { bg: "#252525", accent: "#B08DF8", text: "#C5C5C5" },
		playful: { bg: "#2A1B3D", accent: "#FF79C6", text: "#64B5F6" },
	}

	useEffect(() => {
		let timer: NodeJS.Timeout
		if (isInView) {
			timer = setInterval(() => {
				setStep((prev) => (prev + 1) % totalSteps)
				setText("")
			}, 3000)
		}
		return () => clearInterval(timer)
	}, [isInView])

	useEffect(() => {
		if (step === 0 && isInView) {
			let currentText = ""
			const typeInterval = setInterval(() => {
				if (currentText.length < promptText.length) {
					currentText = promptText.slice(0, currentText.length + 1)
					setText(currentText)
				} else {
					clearInterval(typeInterval)
				}
			}, 50)
			return () => clearInterval(typeInterval)
		}
	}, [step, isInView])

	const buttonVariants = {
		initial: { borderRadius: 8, scale: 1 },
		playful: {
			borderRadius: 24,
			scale: [1, 1.1, 1],
			transition: {
				borderRadius: { duration: 0.3 },
				scale: { duration: 0.5, times: [0, 0.5, 1], ease: "easeInOut" },
			},
		},
	}

	const containerVariants = {
		initial: { backgroundColor: colors.initial.bg },
		playful: {
			backgroundColor: colors.playful.bg,
			transition: { duration: 0.5 },
		},
	}

	const textVariants = {
		initial: { color: colors.initial.text },
		playful: {
			color: colors.playful.text,
			transition: { duration: 0.5 },
		},
	}

	return (
		<motion.div
			className="bg-surface2/30 backdrop-blur-xs p-8 rounded-xl relative overflow-hidden h-[400px]"
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true, margin: "-100px" }}
			onViewportEnter={() => setIsInView(true)}
			onViewportLeave={() => setIsInView(false)}
			transition={{ duration: 0.3 }}
		>
			{/* Chat Interface */}
			<div className="bg-surface1 rounded-lg p-4 mb-4">
				<div className="flex gap-2 mb-3">
					<div className="w-3 h-3 rounded-full bg-red-500"></div>
					<div className="w-3 h-3 rounded-full bg-yellow-500"></div>
					<div className="w-3 h-3 rounded-full bg-green-500"></div>
				</div>
				<div className="flex items-center gap-2">
					<span className="text-accent"></span>
					<div className="font-mono text-sm text-base">
						{text}
						{step === 0 && <span className="animate-pulse">|</span>}
					</div>
				</div>
				<AnimatePresence mode="wait">
					{step > 0 && (
						<motion.div
							initial={{ opacity: 0, y: 10 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: -10 }}
							className="mt-2 text-xs text-base/70 italic"
						>
							<span className="mr-2">🤖</span>
							{aiThoughts[step] || aiThoughts[aiThoughts.length - 1]}
						</motion.div>
					)}
				</AnimatePresence>
			</div>

			{/* App Preview */}
			<motion.div
				className="relative h-48 rounded-lg overflow-hidden"
				variants={containerVariants}
				initial="initial"
				animate={step >= 2 ? "playful" : "initial"}
			>
				{/* Header */}
				<motion.div className="h-10 border-b border-outline flex items-center justify-between px-4" variants={textVariants}>
					<span className="text-sm">My App</span>
					<motion.button
						className="px-4 py-1 bg-accent text-base text-sm"
						variants={buttonVariants}
						animate={step >= 2 ? "playful" : "initial"}
						whileHover={step >= 3 ? { scale: 1.05 } : {}}
					>
						New
					</motion.button>
				</motion.div>

				{/* Content */}
				<div className="grid grid-cols-2 gap-4 p-4">
					{[1, 2, 3, 4].map((i) => (
						<motion.div
							key={i}
							className="bg-surface3/50 rounded-sm p-3"
							variants={buttonVariants}
							animate={step >= 2 ? "playful" : "initial"}
							whileHover={
								step >= 3
									? {
											scale: 1.02,
											transition: { type: "spring", stiffness: 400 },
										}
									: {}
							}
						>
							<motion.div className="h-2 w-3/4 bg-accent/20 rounded-sm mb-2" variants={textVariants} />
							<motion.div className="h-2 w-1/2 bg-accent/20 rounded-sm" variants={textVariants} />
						</motion.div>
					))}
				</div>
			</motion.div>
		</motion.div>
	)
}

export default CustomizationAnimation
