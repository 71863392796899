import React from "react"
import { motion } from "framer-motion"
import Button from "../components/button"

const PricingPage = () => {
	// Card hover animation variants
	const cardVariants = {
		initial: { opacity: 0, y: 20 },
		animate: { opacity: 1, y: 0 },
		whileHover: { scale: 1.05, boxShadow: "0px 0px 20px rgba(229, 253, 120, 0.4)" },
	}

	// Centralized pricing plans data
	const pricingPlans = [
		{
			name: "Free",
			price: "$0",
			description: "Perfect for getting started",
			features: ["Build simple apps", "Basic AI assistance", "Community support"],
			cta: "Get Started",
		},
		{
			name: "Pro",
			price: "$30/mo",
			description: "For serious app builders",
			features: [
				"Everything in Free",
				"3,000 credits monthly",
				"Additional credits $1/100",
				"Advanced AI features",
				"Priority support",
			],
			cta: "Go Pro",
		},
	]

	return (
		<div className="bg-surface1 text-base min-h-screen flex flex-col items-center px-4 pt-32 pb-16 font-body">
			{/* Page Heading */}
			<motion.h1
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.5 }}
				className="text-2xl md:text-3xl font-bold text-heading mb-12"
			>
				Choose Your Plan
			</motion.h1>

			{/* Pricing Cards Container */}
			<div className="flex flex-col md:flex-row gap-8 justify-center">
				{pricingPlans.map((plan) => (
					<motion.div
						key={plan.name}
						variants={cardVariants}
						initial="initial"
						animate="animate"
						whileHover="whileHover"
						className="bg-surface2/20 backdrop-blur-xs p-8 rounded-lg border border-outline/20"
					>
						<div className="flex flex-col items-center w-[300px] h-full">
							<div className="grow flex flex-col items-center">
								<h2 className="text-xl font-semibold mb-2 text-heading">{plan.name}</h2>
								<p className="text-accent text-3xl font-bold mb-3">{plan.price}</p>
								<p className="text-base mb-6 text-center">{plan.description}</p>
								<ul className="space-y-3 mb-8">
									{plan.features.map((feature, index) => (
										<li key={index} className="flex items-center text-base">
											<span className="mr-2 text-accent">✓</span>
											{feature}
										</li>
									))}
								</ul>
							</div>
							<div className="mt-auto pt-4">
								<Button
									variant="galaxy"
									onClick={() => (plan.name === "Pro" ? (window.location.href = "/checkout") : (window.location.href = "#cta"))}
								>
									{plan.cta}
								</Button>
							</div>
						</div>
					</motion.div>
				))}
			</div>
		</div>
	)
}

export default PricingPage
