import { createRouter, createRoute, createRootRoute } from "@tanstack/react-router"
import App from "./App"
import Privacy from "./pages/privacy"
import Terms from "./pages/terms"
import Home from "./pages/home"
import Pricing from "./pages/pricing"
import Checkout from "./pages/checkout"

const rootRoute = createRootRoute({
	component: App,
})

const indexRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/",
	component: Home,
})

const privacyRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/privacy",
	component: Privacy,
})

const termsRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/terms-of-use",
	component: Terms,
})

const pricingRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/pricing",
	component: Pricing,
})

const checkoutRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/checkout",
	component: Checkout,
})

const routeTree = rootRoute.addChildren([indexRoute, privacyRoute, termsRoute, pricingRoute, checkoutRoute])

export const router = createRouter({ routeTree })

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router
	}
}
