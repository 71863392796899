import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import KittyIcon from "../icons/kitty-icon"
interface NavItem {
	label: string
	href: string
}

interface HeaderProps {
	logo?: string
	brandName?: string
	navItems?: NavItem[]
	className?: string
}

const Header: React.FC<HeaderProps> = ({
	brandName = "JigJoy",
	navItems = [
		{ label: "Projects", href: "#projects" },
		{ label: "Features", href: "#features-section" },
		{ label: "FAQ", href: "#faq" },
		{ label: "Pricing", href: "/pricing" },
		{ label: "Get Started", href: "#cta" },
	],
	className = "",
}) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const handleNavClick = (href: string) => {
		setIsMenuOpen(false)

		// If it's a route (starts with '/'), use window.location
		if (href.startsWith("/")) {
			window.location.href = href
			return
		}

		// If we're not on the home page and it's a hash link, navigate home first
		if (window.location.pathname !== "/" && href.startsWith("#")) {
			window.location.href = "/"
			// The page will reload, so we'll store the target in sessionStorage
			sessionStorage.setItem("scrollTarget", href)
			return
		}

		// For hash links on home page, scroll to element
		if (href.startsWith("#")) {
			// Add a small delay to allow the menu to close before scrolling
			setTimeout(() => {
				const element = document.querySelector(href)
				element?.scrollIntoView({ behavior: "smooth" })
			}, 300)
		}
	}

	return (
		<header className={`fixed top-0 left-0 right-0 z-50 bg-surface2/30 backdrop-blur-xs ${className}`}>
			<div className="flex justify-between items-center px-8 py-4">
				<a
					href="/"
					className="flex items-center"
					onClick={(e) => {
						e.preventDefault()
						window.location.href = "/"
						setIsMenuOpen(false)
					}}
				>
					<KittyIcon width="24" height="24" className="ml-2 text-accent fill-current" />
					<h1 className="ml-2 font-bold text-xl">
						<span className="galaxy-text">{brandName}</span>
					</h1>
				</a>
				<nav className="hidden md:flex space-x-6">
					{navItems.map((item, index) => (
						<a
							key={index}
							href={item.href}
							onClick={(e) => {
								e.preventDefault()
								handleNavClick(item.href)
							}}
							className="text-clickable hover:text-hover font-bold transition-colors duration-200"
						>
							{item.label}
						</a>
					))}
				</nav>
				<button
					onClick={() => setIsMenuOpen(!isMenuOpen)}
					className="text-clickable hover:text-hover transition-colors duration-200 md:hidden"
					aria-label="Toggle menu"
				>
					{isMenuOpen ? "Close" : "Menu"}
				</button>
			</div>

			{/* Mobile menu */}
			<AnimatePresence>
				{isMenuOpen && (
					<motion.div
						initial={{ opacity: 0, height: 0 }}
						animate={{ opacity: 1, height: "auto" }}
						exit={{ opacity: 0, height: 0 }}
						transition={{ duration: 0.3 }}
						className="md:hidden bg-surface2/95 backdrop-blur-xs"
					>
						<nav className="flex flex-col px-8 py-4">
							{navItems.map((item, index) => (
								<a
									key={index}
									href={item.href}
									onClick={(e) => {
										e.preventDefault()
										handleNavClick(item.href)
									}}
									className="py-3 text-clickable hover:text-hover transition-colors duration-200 border-b border-surface3 last:border-none"
								>
									{item.label}
								</a>
							))}
						</nav>
					</motion.div>
				)}
			</AnimatePresence>
		</header>
	)
}

export default Header
