import React from "react"
import KittyIcon from "../icons/kitty-icon"

const CheckoutPage = () => {
	return (
		<div className="bg-surface1 text-base min-h-screen flex flex-col items-center justify-center px-4 pt-32 pb-16 font-body">
			<KittyIcon width="120" height="120" className="mb-8" />
			<h1 className="text-4xl md:text-5xl font-bold mb-6 galaxy-text text-center">Under construction</h1>
			<p className="text-xl text-base text-center">Paddle checkout goes here</p>
		</div>
	)
}

export default CheckoutPage
