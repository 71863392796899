import React from "react"

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	href?: string
	children: React.ReactNode
	className?: string
	variant?: "default" | "galaxy"
}

const Button: React.FC<ButtonProps> = ({ href, children, className = "", variant = "default", ...props }) => {
	const baseClasses =
		variant === "default"
			? "inline-block px-6 py-3 text-sm font-semibold rounded-full transition-all duration-200 bg-primary hover:bg-hover text-surface1 hover:text-surface1 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
			: "galaxy-button inline-block px-6 py-3 text-sm font-semibold rounded-full transition-all duration-200 bg-primary hover:bg-transparent text-surface1 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
	const combinedClasses = `${baseClasses} ${className}`

	if (href) {
		return (
			<a href={href} className={combinedClasses}>
				{children}
			</a>
		)
	}

	return (
		<button className={combinedClasses} {...props}>
			{children}
		</button>
	)
}

export default Button
