import React from "react"
import { motion } from "framer-motion"

const AnimatedBackground = ({ children }) => {
	return (
		<div className="relative">
			<motion.div
				className="fixed inset-0 z-0 opacity-20"
				initial={{
					backgroundImage: "radial-gradient(circle at 30% 30%, #B08DF8 0%, rgb(0, 0, 0) 50%)",
				}}
				animate={{
					backgroundImage: [
						"radial-gradient(circle at 30% 30%, #B08DF8 0%, rgb(0, 0, 0) 50%)",
						"radial-gradient(circle at 70% 70%, #B08DF8 0%, rgb(0, 0, 0) 50%)",
						"radial-gradient(circle at 30% 70%, #B08DF8 0%, rgb(0, 0, 0) 50%)",
						"radial-gradient(circle at 70% 30%, #B08DF8 0%, rgb(0, 0, 0) 50%)",
						"radial-gradient(circle at 30% 30%, #B08DF8 0%, rgb(0, 0, 0) 50%)",
					],
				}}
				transition={{
					duration: 20,
					repeat: Infinity,
					ease: "linear",
				}}
			/>
			{children}
		</div>
	)
}

export default AnimatedBackground
