import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { RouterProvider } from "@tanstack/react-router"
import { router } from "./router"

const root = document.createElement("div")
document.body.appendChild(root)
const rootDiv = ReactDOM.createRoot(root)

rootDiv.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>,
)
